import React from "react";
import { FaYoutube, FaTelegram } from "react-icons/fa";
import { FaVk } from "react-icons/fa6";
import "../styles/ContactsPage.css";

const socialLinks = [
  {
    id: "telegram",
    icon: <FaTelegram className="cnp--tg__icon" />,
    url: "https://t.me/moscowftt",
    text: "telegram.me/moscowftt",
  },
  {
    id: "youtube",
    icon: <FaYoutube className="cnp--yt__icon" />,
    url: "https://www.youtube.com/MoscowTableTennisFederation",
    text: "youtube.com/MoscowTableTennisFederation",
  },
  {
    id: "vk",
    icon: <FaVk className="cnp--vk__icon" />,
    url: "https://vk.com/moscowftt",
    text: "vk.com/moscowftt",
  },
];

const ContactsPage = () => {
  return (
    <div className="cnp--container">
      <h1 className="cnp--title">Контакты</h1>
      <p className="cnp--title__text">Мы в социальных сетях</p>

      <div className="cnp--contacts__container">
        <p className="cnp--email">email: mfntmail@gmail.com</p>

        <div className="cnp--card__container">
          {socialLinks.map(({ id, icon, url, text }) => (
            <a
              key={id}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="cnp--card"
            >
              {icon}
              <h2 className="cnp--link__text">{text}</h2>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactsPage;
